<template>
  <div class="decorationRecordList-wrapper">
    <list ref="list" exportPermission="export" exportMethod="new" :searchUrl="searchUrl" :exportUrl="exportUrl"
      :searchParams.sync="searchParams" :headers="headers">
      <template #headerSlot>
        <!-- <v-button
          permission="add"
          text="新增装修登记"
          @click="create"
        ></v-button> -->
      </template>
      <template #searchSlot>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>

        <v-input label="苑" v-model="searchParams.blockName"></v-input>
        <v-input label="幢" v-model="searchParams.buildingNumber"></v-input>
        <v-input label="单元" v-model="searchParams.unit"></v-input>
        <v-input label="室" v-model="searchParams.room"></v-input>
        <v-select label="查验状态" v-model="searchParams.status" :options="statusOps"></v-select>

        <v-select2 label="查验人" v-model="searchParams.examineUserId" v-bind="examineParams"></v-select2>
        <v-datepicker label="计划查验时间" :startTime.sync="searchParams.planStartTime"
          :endTime.sync="searchParams.planEndTime"></v-datepicker>
        <v-datepicker label="实际查验时间" :startTime.sync="searchParams.realStartTime"
          :endTime.sync="searchParams.realEndTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button permission="reassignment" v-show="scope.row.status === 0" text="转派" type="text"
          @click="edit(scope.row)"></v-button>
      </template>
      <el-dialog v-if="settingShow" :visible.sync="settingShow" label-width="0" width="576px" center>
        <div slot="title" class="dialog-title">转派查验任务</div>
        <div style="margin-bottom: 15px">
          <span style="margin-right: 15px">当前查验人:</span><span>{{ examineUserName }}</span>
        </div>
        <div class="examine">
          <v-select2 :width="250" label="转派给" v-model="examineUserId" v-bind="examineParams" :subjoin="roleExtraParams"
            @onChange="onChange"></v-select2>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="settingShow = false">取 消</el-button>
          <el-button type="primary" @click="examineUserOK">确 定</el-button>
        </span>
      </el-dialog>
    </list>
  </div>
</template>
  
<script>
import {
  exportListURL,
  getExamineList,
  getUserListURL,
  putExamine
} from './api'
import { communityParams, tenantParams } from 'common/select2Params'

export default {
  name: 'OnSiteInspectionList',
  data() {
    return {
      dynamicValidateForm: {
        domains: [{
          examineItem: ''
        }],
        delivery: false
      },
      settingShow: false, // 装修设置弹窗
      examineUserName: '', // 查验人
      examineUserName2: '', // 查验人
      examineUserId: '',
      width: 180,
      searchUrl: getExamineList,
      exportUrl: exportListURL,
      communityParams,
      tenantParams,
      // 查验人列表
      examineParams: {
        searchUrl: getUserListURL,
        request: {
          text: 'username',
          value: 'id'
        },
        response: {
          text: 'username'
        }
      },
      searchParams: {
        examineUserId: '',
        communityId: '',
        status: undefined,
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
        planStartTime: '',
        planEndTime: '',
        realStartTime: '',
        realEndTime: '',
      },
      decorationId: '',
      communityId: '',
      headers: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'roomAddress',
          label: '房号'
        },
        {
          prop: 'status',
          label: '查验状态',
          formatter(row) {
            return row.status === 1 ? '通过' : row.status === 2 ? '不通过' : '待查验'
          }
        },
        {
          prop: 'examineUserName',
          label: '查验人',
        },
        {
          prop: 'planTime',
          label: '计划查验时间',
        },
        {
          prop: 'realTime',
          label: '实际查验时间'
        },
      ],
      statusOps: [  // 查验状态
        {
          text: '全部',
          value: undefined
        }, {
          text: '待查验',
          value: 0
        }, {
          text: '通过',
          value: 1
        }, {
          text: '不通过',
          value: 2
        }
      ]
    }
  },
  computed: {
    // comput () {
    //   var newTime = new Date().getTime() * 1000000 // 现在
    //   var planTime = new Date(this.searchParams.planTime).getTime() * 1000000  // 计划时间
    //   var oldTime = new Date().getTime(this.searchParams.planTime) * 1000000 - 3 * 60 * 60 * 1000 * 1000000 // 三天前
    //   return (Number(newTime) > Number(oldTime)) && (Number(newTime) < Number(planTime))
    // }
    roleExtraParams() {
      return {
        communityId: this.communityId
      }
    },
  },
  methods: {
    onChange(val, echo) {
      console.log(val, 'onChange');
      this.examineUserId = val ? val.id : undefined
      this.examineUserName2 = val ? val.username : undefined
    },
    edit(row) {

      this.communityId = row.communityId
      this.decorationId = row.decorationId
      this.settingShow = true
      this.examineUserName = row.examineUserName
    },
    async examineUserOK() {
      // console.log(111);
      this.settingShow = false
      let params = {
        decorationId: this.decorationId,
        examineUserId: this.examineUserId,
        examineUserName: this.examineUserName2
      }
      // console.log(params);
      if (this.examineUserId) {
        const res = await this.$axios.put(putExamine, { ...params })
        console.log(res, 'res-----------');
        if (res.status === 100) {
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
        this.$refs.list.searchData()
      }
    }

  }
}
</script>
<style scoped lang="scss">
.dialog-footer {
  text-align: center;
}

.dialog-title {
  font-size: 23px;
  font-weight: 700;
  color: #666666;
}

::v-deep .v-select2-container .select2-text {
  margin-right: 20px;
}

.examin {
  ::v-deep .dropdown-toggle {
    width: 300px;
  }
}
</style>
