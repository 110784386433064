// 获取列表
// const getListURL = `${API_CONFIG.baseURL}smDecorationAction!list.action`
// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}decoration/list`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}decoration/export/info`
// 上传接口
const uploadUrl = `${API_CONFIG.uploadURL}?module=decoration`
// 修改时间
const editDateUrl = `${API_CONFIG.butlerBaseURL}decoration/code`

// 获取装修阶段 APP2-1309 装修记录增加装修阶段搜索APP2-1336 前端 - 装修记录增加装修阶段搜索
const getDecorationStageURL = `${API_CONFIG.butlerBaseURL}inspect/category/second`

// 查验现场列表
const getExamineList = `${API_CONFIG.butlerBaseURL}decoration/examine/list`

// 查验人
const getUserListURL = `${API_CONFIG.butlerBaseURL}user/search/community/username`

// 转派查验人
const putExamine = `${API_CONFIG.butlerBaseURL}decoration/examine`

export {
  getListURL,
  exportListURL,
  uploadUrl,
  editDateUrl,
  getDecorationStageURL,
  getExamineList,
  getUserListURL,
  putExamine
}
